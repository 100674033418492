import React, { ReactElement } from "react";

import { TermsOfService } from "../components/Footer/TermsOfService";

export interface PrivacyPolicy {
  success: boolean;
  content: string;
}

const Index = (): ReactElement => {
  return <TermsOfService isSeparatePage={true} />;
};

export default Index;
